.openModalbutton {
  background-color: white;
  font-size: 20px;
  padding: 3px;
  border: none;
  border-bottom: 2px solid #000;
  font-weight: 300;
  margin-top: 30px;
  color: #000;
}

.openModalbutton:hover {
  background-color: rgb(227, 194, 253);
  font-size: 24px;
}
