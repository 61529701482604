.containerFormcontacme {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  gap: 20px;
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
}

.formContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 10px;
  height: 100%;
}

.labelForm {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  margin-top: 10px;
}

.inputForm,
.inputFormMessge {
  border-radius: 10px;
  border: 1px solid grey;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.sendButton {
  background-color: #bb99f2;
  color: #ffffff;
  padding: 10px;
  border: none;
  height: 50px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sendButton:hover {
  background-color: #c6abf1;
}

@media (max-width: 700px) {
  .containerFormcontacme {
    width: 100%;
    padding: 10px;
  }

  .inputForm,
  .inputFormMessge {
    font-size: 14px;
  }

  .sendButton {
    font-size: 18px;
  }

  .labelForm {
    font-size: 18px;
  }
}

@media (max-width: 400px) {
  .inputForm,
  .inputFormMessge {
    font-size: 10px;
  }
  .containerFormcontacme {
    width: 70%;
    height: 70%;
  }
  .sendButton {
    font-size: 12px;
  }

  .labelForm {
    font-size: 12px;
  }
  .formContainer {
    height: 300px;
  }
}
