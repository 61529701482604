.logoName {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
  border: none;
  background-color: transparent;
  color: black;
  font-size: 50px;
  z-index: 999;
}
.logoName:hover {
  color: #aaa8a8;
}
@media (max-width: 768px) {
  .logoName {
    font-size: 30px;
    margin-left: 1px;
  }
}
