.playButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.playVideoButton {
  background-color: #bb99f2;
  border: none;
  border-radius: 60px;
  height: 80px;
  width: 80px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes butonanimation {
  0% {
    background-color: #918ce7;
  }
  25% {
    background-color: #8681ec;
  }
  50% {
    background-color: #716af2;
  }
}
.playVideoButton {
  animation: butonanimation 2s infinite;
}

.playVideoButton:hover {
  background-color: #d6d4fe;
}

.imgPlayVideoButton {
  height: 70px;
  width: 70px;
}

.videoCard {
  margin-top: 20px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoPlayer {
  width: 100%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
}
@media (max-width: 768px) {
  .ParagraphinfoP {
    margin: 30px 30px 30px 30px;
  }
}
