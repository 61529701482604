.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-flow: column;
}

.closeButton {
  background: none;
  border: none;
  font-size: 25px;
  cursor: pointer;
  border-radius: 20px;
  background-color: #d9d9d9;
}
.closeButton:hover {
  background-color: #bb99f2;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.containerButons {
  height: 100px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.demoLink {
  background-color: #bb99f2;
  display: block;
  border-radius: 15px;
  height: 50px;
  width: 100px;
  align-content: center;
  text-decoration: none;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}
.RepoLink {
  background-color: #bb99f2;
  display: block;
  border-radius: 15px;
  height: 50px;
  width: 100px;
  align-content: center;
  text-decoration: none;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}
.demoLink:hover {
  background-color: #c0bbff;
}
.RepoLink:hover {
  background-color: #c0bbff;
}
.imagePorfolio {
  height: 300px;
  width: 300px;
}
