.contactmeContainer {
  display: flex;
  width: 100%;
  height: 75vh;
}
.contactmeContaineChildOne {
  display: flex;
  align-self: center;
  width: 50%;
  height: 100%;
  padding: 70px;
}
.contactmeContaineChildTwo {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 50%;
  height: 100%;
}
@media (max-width: 768px) {
  .contactmeContaineChildTwo {
    display: none;
  }
  .contactmeContaineChildOne {
    width: 100%;
    height: 80%;
    padding: 0%;
  }
}
@media (max-width: 400px) {
  .contactmeContaineChildTwo {
    display: none;
  }
  .contactmeContaineChildOne {
    width: 100%;
    height: 30%;
    padding: 0%;
  }
}
