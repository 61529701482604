.contactInformationParagraph {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 300px;
}
.contactInformationParagraphp {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  margin: 0;
  padding: 10px;
}

@media (max-width: 1024px) {
  .contactInformationParagraphp {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .contactInformationParagraphp {
    font-size: 20px;
  }
}
