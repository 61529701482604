.label {
  padding: 30px;
  font-size: 30px;
  box-sizing: border-box;
  height: 100px;
  margin-bottom: -10px;
}

@media (max-width: 768px) {
  .label {
    font-size: 25px;
  }
}
@media (max-width: 400px) {
  .label {
    width: auto;
    font-size: 16px;
  }
}
