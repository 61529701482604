.informationContainer {
  display: flex;
  flex-flow: column;
  justify-self: center;
  align-items: center;
  height: 35%;
  margin-bottom: 100px;
}
@media (max-width: 1024px) {
  .informationContainer {
    padding: 8px;
  }
}

@media (max-width: 768px) {
  .informationContainer {
    padding: 5px;
  }
}
