.containerSocialmedialogo {
  display: flex;
  justify-content: center;
  z-index: 10;
  background-color: transparent;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  pointer-events: none;
  padding: 10px 0;
}

.containerSocialmedialogo > * {
  pointer-events: auto;
}

@media (max-width: 599px) {
  .containerSocialmedialogo {
    background-color: white;
    bottom: 10px;
    padding: 10px 0;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .containerSocialmedialogo {
    bottom: 10px;
    padding: 10px 0;
  }
}

@media (min-width: 1025px) {
  .containerSocialmedialogo {
    position: relative;
    margin-top: 50px;
    background-color: transparent;
    padding: 0;
  }
}
