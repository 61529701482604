.aboutMeContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  grid-template-rows: auto;
  place-content: center;
}

.aboutMeContainerChildOne {
  height: 100%;
  width: 100%;
}
.aboutMeContainerChildTwo {
  height: 100%;
  width: 100%;
}
@media (max-width: 768px) {
  .aboutMeContainerChildTwo {
    height: 10px;
    width: 10px;
  }
  .aboutMeContainer {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
  }
  .aboutMeContainerChildTwo {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  .aboutMeContainerChildOne {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
