.responsive-photo {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  animation: moveright 1.5s ease-out forwards;
}
@keyframes moveright {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
@media (min-width: 1024px) {
  .responsive-photo {
    max-width: 450px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .responsive-photo {
    max-width: 450px;
  }
}

@media (max-width: 767px) {
  .responsive-photo {
    display: none;
  }
}
