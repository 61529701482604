.ParagraphinfoP {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 40px;
  margin: 10px 10px 30px 20px;
}

@media (max-width: 1024px) {
  .ParagraphinfoP {
    font-size: 32px;
    margin: 0px 8px 20px 0px;
  }
}

@media (max-width: 768px) {
  .ParagraphinfoP {
    font-size: 30px;
    margin: 0px 8px 20px 0px;
  }
}

@media (max-width: 400px) {
  .ParagraphinfoP {
    font-size: 20px;
  }
}
