.titleHome {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 64px;
}
@media (max-width: 767px) {
  .titleHome {
    font-size: 55px;
    margin: 2px 0px 10px 0px;
  }
}
@media (max-width: 400px) {
  .titleHome {
    font-size: 30px;
  }
}
