.containerNav {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 0px 30px 0px 30px;
  height: 80px;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  gap: 40px;
}

.hamburger {
  display: none;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .containerNav {
    grid-template-columns: auto auto;
    padding: 10px 20px;
  }
  .buttonsContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: absolute;
    top: 40px;
    right: 0;
    width: 100%;
    height: 140px;
    display: none;
    background-color: white;
    z-index: 1;
  }
  .buttonsContainer {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }

  .buttonsContainer.open {
    display: flex;
  }
  .logoName {
    font-size: 30px;
    grid-column: 1;
    margin-right: auto;
  }

  .hamburger {
    display: block;
    position: absolute;
    right: 20px;
    color: black;
    z-index: 2;
    padding: 10px;
  }
}
@media (max-width: 1024px) {
  .containerNav {
    padding: 15px 20px;
  }

  .logoName {
    font-size: 40px;
  }

  .Navbarbutton {
    font-size: 32px;
  }
}
