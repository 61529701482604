.card {
  display: flex;
  justify-content: center;
  flex-flow: column;
}

.cardChildOne {
  background-color: #dbceef;
  height: 250px;
  width: 300px;
}

.cardChildTwo {
  background-color: rgb(255, 255, 255);
  height: 320px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
