.homestyle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  height: 100%;
}

.rightSide {
  display: grid;
  grid-template-rows: auto auto auto;
  place-items: center;
  padding: 20px;
  animation: moveup 1.5s ease-out forwards;
  height: 90%;
}

@keyframes moveup {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media (min-width: 1024px) {
  .responsive-photo {
    max-width: 450px;
  }
}

.leftSide {
  display: grid;
  height: 100%;
  place-items: center;
  padding: 20px;
}

@media (max-width: 768px) {
  .homestyle {
    grid-template-columns: 1fr;
    align-items: start;
  }
  .leftSide {
    display: none;
  }
  .rightSide {
    width: auto;
  }
}
@media (max-width: 400px) {
  .homestyle {
    grid-template-columns: 1fr;
    align-items: start;
  }
  .leftSide {
    display: none;
  }
  .rightSide {
    height: 90%;
  }
}

/* .playButtonWrapper {
} */
