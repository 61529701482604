.cvButton {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 40px;
  background-color: #965eeb;
  border: none;
  color: aliceblue;
  border-radius: 20px;
  padding: 10px;
  height: 70px;
  width: 200px;
}
.cvButton:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #c5a6f4;
  color: black;
}
@media (max-width: 1024px) {
  .cvButton {
    font-size: 32px;
    padding: 8px;
  }
}

@media (max-width: 768px) {
  .cvButton {
    font-size: 24px;
    padding: 6px;
    border-radius: 15px;
  }
}
