.Navbarbutton {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 36px;
  border: none;
  background-color: transparent;
  border-radius: 10px;
  color: black;
}
.Navbarbutton:hover {
  background-color: #c0bbff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
@media (max-width: 768px) {
  .Navbarbutton {
    font-size: 20px;
  }
}

@media (min-width: 600px) and (max-width: 1024px) {
  .Navbarbutton {
    font-size: 20px;
  }
}
