.containerPortfolio {
  display: flex;
  flex-flow: column;

  width: 100%;
  margin-top: 30px;
}

.containerPortfolioOne {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-bottom: 100px;
  height: calc(100% - 100px);
  align-items: center;
}

@media (max-width: 768px) {
  .containerPortfolioOne {
    padding-bottom: 100px;
    flex-grow: 1;
  }

  .containerPortfolioTwo {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
